.logos {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 2vw 0 2.5vw;
  background: rgb(250,250,250);
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .logos {
    flex-wrap: wrap;
  }
}

.logos span {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .logos span {
    width: 50%;
    height: 7vh;
    display: flex;
    align-items: center;
  }
}

.logos img {
  width: 70%;
}

.logos .superInvite {
  width: 60%;
}

.logos .visualFunding {
  width: 80%;
}

.logos .cupManager {
  width: 75%;
}

.invite {
  background: rgb(58,90,101);
  background: var(--clr-invite-gradient);
}

.invite {
  padding: 2rem 2rem 1rem 2rem;
}

.invite img {
  width: clamp(280px, 30%, 350px);
  margin: 0 auto;
  display: block;
}