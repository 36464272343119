.children {
  scroll-snap-align: start;
  width: 100%;
  height: var(--app-height);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}

.employees {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
  width: 80vw;
  max-width: 1000px;
  justify-content: center;
}

.info {
  display: flex;
  width: 120px;
  flex-direction: column;
  align-items: center;
  border: 0;
  background: transparent;
  font-size: 14px;
  cursor: pointer;
  transition: .15s ease;
}

.info:hover {
  transform: scale(1.2) translateY(-5px);
  filter: drop-shadow(0px 5px 10px rgba(0,0,0, .04));
}

.info h3 {
  font-size: 1em;
  margin: 0 0 .3rem;
  color: black;
}

.info h3 .last_name {
  color: black;
}

.info img {
  margin-bottom: .3rem;
  width: 100px;
}


.info span {
  font-size: 1em;
  color: gray;
}

/* MODAL */
.close {
  position: absolute;
  top: 2vw;
  right: 2vw;
  border: 0;
  background: transparent;
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition: .1s;
  display: flex;
  align-items: center;
  gap: .5rem;
}

.close i {
  font-size: 3rem;
}

.close:hover {
  transform: scale(1.1);
}

.modal_employee {
  color: white;
  display: flex;
  align-items: center;
  gap: 2rem;
  margin: 0 4rem;
}

.modal_employee img {
  max-width: 400px;
  width: 50%;
  height: 50%;
}


.modal_employee p {
  max-width: 40vw;
  line-height: 1.4rem;
  margin-bottom: 2rem;
}

.modal_employee h2 {
  margin-bottom: .2rem;
  font-size: 1.4rem;
}

.modal_employee .title {
  font-weight: 700;
  margin-bottom: 1rem;
  display: block;
}

.contacts span {
  display: flex;
  align-items: center;
  gap: .5rem;
  margin-bottom: .5rem;
  font-weight: 700;
}

.contacts span i {
  font-size: 1.2rem;
}

@media screen and (max-width: 768px) {

  .info {
    width: 90px;
  }

  .info h3 .last_name {
    display: none;
  }

  .employees {
    width: 90vw;
    gap: .5rem;
  }
}

@media screen and (max-width: 850px) {

  .modal_employee img {
    width: 70%
  }

  .modal_employee p {
    max-width: 100%;
    line-height: 1.4rem;
    margin-bottom: 1rem;
    display: block;
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .employee_info {
    margin: 0 1.5rem;
  }

  .close {
    font-size: 1rem;
  }

  .close i {
    font-size: 1.5rem;
  }
}

:global(.ReactModal__Body--open) {
  height: 100vh;
  overflow: hidden;
}

:global(.ReactModal__Overlay) {
  opacity: 0;
  transform: translateX(-100%);
  transition: all 200ms ease-in-out;
}

:global(.ReactModal__Overlay--after-open) {
  opacity: 1;
  transform: translateX(0px);
}

:global(.ReactModal__Overlay--before-close) {
  opacity: 0;
  transform: translateX(-100%);
}

/* / MODAL */

@media screen and (max-width: 768px) {
  .info {
    font-size: 12px;
  }

  .info img {
    width: 50px;
  }
}

.arrow_btn {
  background: none;
  border: 0;
  color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 2rem;
  z-index: 100;
  padding: 30px;
}

.arrow_btn.right {
  right: 0;
}

.arrow_btn.left {
  left: 0;
}

.modal {
  background: linear-gradient(-45deg, rgba(58,90,101,1) 0%, #5fa746 100%);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  border: 0;
  z-index: 9000;
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 400px) {
  .info img {
    width: 40px;
  }
}


:global(.slide_employee_right) .slider,
:global(.slide_employee_left) .slider {
  position: absolute;
  left: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 2rem;
  align-items: center;
  width: 50vw;
}

@media screen and (max-width: 1200px) {
  :global(.slide_employee_right) .slider,
  :global(.slide_employee_left) .slider {
    width: 80vw;
  }
}

@media screen and (max-width: 850px) {
  :global(.slide_employee_right) .slider,
  :global(.slide_employee_left) .slider {
    flex-direction: column;
    width: 80vw;
    gap: 0;
  }
}

:global(.slide_employee_right .enter) {
  opacity: 0;
  transform: translateX(100%);
}

:global(.slide_employee_right .enter-active) {
  transition: var(--cssTransitionTimeout) ease-in-out;
  opacity: 1;
  transform: translateX(-50%);
}

:global(.slide_employee_right .exit) {
  opacity: 1;
  transform: translateX(-50%);
}

:global(.slide_employee_right .exit-active) {
  transition: var(--cssTransitionTimeout) ease-in-out;
  opacity: 0;
  transform: translateX(-100%);
}

/**/

:global(.slide_employee_left .enter) {
  opacity: 0;
  transform: translateX(-150%);
}

:global(.slide_employee_left .enter-active) {
  transition: var(--cssTransitionTimeout) ease-in-out;
  opacity: 1;
  transform: translateX(-50%);
}

:global(.slide_employee_left .exit) {
  opacity: 1;
  transform: translateX(-50%);
}

:global(.slide_employee_left .exit-active) {
  transition: var(--cssTransitionTimeout) ease-in-out;
  opacity: 0;
  transform: translateX(0%);
}