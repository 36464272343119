.footer {
  scroll-snap-align: start;
  width: 100%;
  height: var(--app-height);
  background: var(--clr-green);
  color: white;
  display: flex;
  align-items: start;
  justify-content: center;
  font-size: clamp(18px, 3vw, 32px);
  position: relative;
  overflow: hidden;
}

.footer.top_img .employees_top {
  opacity: 1;
}

.footer img {
  object-fit: cover;
  min-width: 100%;
  min-height: 100%;
}

.info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgba(0,0,0, .6);
  padding: 3rem;
  transition: .3s;
}

.info:hover {
  background: rgba(0,0,0, .8);
}

.info h2 {
  margin-top: 0;
}



.footer a {
  color: white;
  padding-bottom: .3rem;
}

.footer p {
  display: flex;
  align-items: center;
}

.footer i {
  font-size: 2rem;
}

.icon {
  height: 4rem;
  width: 4rem;
  border-radius: 100px;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
}

@media screen and (max-width: 768px) {
  .footer i {
    font-size: 1rem;
  }
  
  .icon {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 100px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1rem;
  }

  .info {
    padding: 2rem;
  }
}

.icon i {
  color: var(--clr-green);
}