.children {
  scroll-snap-align: start;
  width: 100%;
  height: var(--app-height);
}

.spotLine {
  display: flex;
  height: 50%;
  flex-wrap: wrap;
}

.background {
  background-size: cover;                     
  background-repeat: no-repeat;
  background-position: center center;
  height: 100%;
  width: 100%;
}

.spotLine > div {
  width: 50%;
  flex-wrap: wrap;
}

.aboutChildren {
  display: flex;
  align-items: center;
  justify-content: center;
}

.aboutChildren article {
  width: 60%;
}

.logo {
  width: 60%;
  min-width: 200px;


  &.superinvite {
    width: 47%;
    min-width: 170px;
  }

  &.cupinvite {
    width: 53%;
    min-width: 180px;
  }
}

.s_header {
  display: flex;
  column-gap: 1rem;
}

.s_logo {
  height: 40px;
  display: inline-block;
}

@media screen and (max-width: 768px) {
  .s_header {
    flex-wrap: wrap;
  }
  .spotLine:nth-child(2) .aboutChildren {
    order: 2;
  }

  .spotLine:nth-child(2) > div:nth-child(1) {
    order: 1;
  }
}

@media screen and (max-width: 960px),
screen and (max-height: 500px) {
  .spotLine > div {
    min-width: 100%;
  }

  .backgroundContainer {
    display: none;
  }
  
}

@media screen and (max-height: 800px) {
  .aboutChildren article {
    width: 60%;
  }

  .aboutChildren article p {
    font-size: 12px;
  }

  .aboutChildren article h3 {
    margin: 0;
  }

  .logo{
    width: 170px;
  }
}