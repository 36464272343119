.navigation {
  position: fixed;
  padding-right: max(3vw, 1rem);
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
  transition: .5s;
  pointer-events: none;
  font-size: 19px;
  color: var(--nav-color)
}

.navigation.top {
  top: 25%;
}

.navigation .menuText {
  background: var(--nav-color);
  color: var(--nav-text-color);
  padding: .3rem 1rem;
  margin-right: .3rem;
}

.navigation.light .menuText {
  background: transparent;
}

@media screen and (max-width: 960px),
screen and (max-height: 800px) {
  .navigation.top {
    top: 50%;
  }
}

.navRing {
  width: 19px;
  height: 19px;
  border-radius: 20px;
  border: 2px solid;
}

.navRing:hover {
  background: var(--nav-color);
}

.navRing.selected {
  width: 19px;
  height: 19px;
  border-radius: 20px;
  background: var(--nav-color);
  border: 2px solid;
}

.lineBetween {
  display: inline-flex;
  height: 15px;
  position:relative;
}


.lineBetween > div {
  background: var(--nav-color);
  width: 2px;
  height: 15px;
  position: absolute;
  right: 10px;
  transition: background .5s;
} 

.light .lineBetween > div {
  background: white;
}

.link {
  max-height: 1em;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  pointer-events: none;
}

.btn {
  border: 0;
  background: transparent;
  color: inherit;
  pointer-events: all;
  padding: 0;
  margin: 0;
}

.link i {
  font-size: 21px;
}

.linkTextContainer {
  overflow: hidden;
  display:block;
  position: relative;
  pointer-events: none;
  padding: .3rem;
}

@media screen and (max-width: 960px) {
  .linkTextContainer {
    opacity: 0;
  }
}

.menuText {
  position: relative;
  left: 100%;
  transition: all .25s;
  opacity: 0;
  padding-right: .5rem;
  pointer-events: none;
}


.link:hover .menuText {
  left: 0;
  opacity: 1;
}

.link:hover .menuText {
  left: 0;
  opacity: 1;
}