:root {
  --clr-green: #41ab34;
  --clr-green-dark: #2b861f;
  --clr-light-green: #fbfffa;
  --clr-blue: #10435f;
  --clr-invite-gradient: linear-gradient(-45deg, rgba(58,90,101,1) 0%, #5fa746 100%)
}

.App {
  font-size: clamp(16px, 1.8vw, 20px);
	overflow-y: scroll;
  height: var(--app-height);
  background-size: cover;                     
  background-repeat: no-repeat;
  background-position: center center;
}

.main {
  width: 100%;
  min-height: var(--app-height);
}