.innerWrapper {
  max-width: 1000px;
}

.info {
  /*background: rgba(250, 250, 250, 1);*/
  padding: 3vw 5vw;
}


.logos {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin-top: 1vw;
  padding: 2vw 0 2.5vw;
  justify-content: center;
}

@media screen and (max-width: 768px) {
  .logos {
    flex-wrap: wrap;
  }
}

.logos span {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .logos span {
    width: 50%;
    height: 7vh;
  }

  .logos .superInvitar {
    margin-top: -3.5vw;
  }
}

.logos img {
  width: 70%;
}

.logos .superInvite {
  width: 60%;
}

.logos .superInvitar {
  width:67%;
}

.logos .cupinsight {
  width: 75%;
}

.logos .cupManager {
  width: 75%;
}

.children {
  width: 85vw;
  min-height: var(--app-height);
  display: flex;
  align-items: center;
  justify-content: center;
  scroll-snap-align: start;
  margin: auto;
}