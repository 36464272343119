

.myLink {
  --time-anim: .15s;
  padding: 1rem 0;
  border-radius: 100px;
  text-decoration: none;
  color: var(--clr-blue);
  display: inline-flex;
  align-items: center;
  position: relative;
  transition: var(--time-anim);
}

.myLink:hover {
  cursor: pointer;
  padding: 1rem 1.5rem;
  background: #e9f3e9;
}

.myLink span {
  transition: var(--time-anim);
}

.myLink:hover span {
  padding-left: 2rem;
}

.myLink i {
  font-size: 1.6rem;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  transition: var(--time-anim);
}

.myLink:hover i {
  opacity: 1;
}

@media screen and (max-height: 800px) {
  .myLink {
    padding: .3rem 1rem;
    font-size: 1rem;
    margin-top: 0;
  }

  .myLink i {
    font-size: 18px;
  }
}