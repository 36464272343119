.innerWrapper {
  max-width: 1000px;
}

.innerWrapper h1 {
  margin-top: 0;
}

.preamble {
  margin-bottom: 4rem;
  font-weight: 700;
}

.date {
  margin-top: 4rem;
  font-style: italic;
  display: block;
}

.info h2 {
  margin-top: 5rem;
}

hr {
  border: 0;
  height: 1px;
  background: silver;
  width: 50%;
  text-align: left;
  margin: 0 auto 0 0;
}

.innerWrapper p,
.innerWrapper ul {
  margin-bottom: 2rem;
}

.innerWrapper li {
  margin-bottom: .5rem;
}

.info {
  /*background: rgba(250, 250, 250, 1);*/
  padding: 3vw 5vw;
  line-height: 2rem;
}

.date {
  color: gray;
  font-size: .9rem;
}


.children {
  width: 85%;
  min-height: var(--app-height);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  padding: 0 7.5%;
  overflow: hidden;
}

.perks_grid {
  display: grid;
  grid-column-gap: 1.5rem;
  grid-row-gap: 1.5rem;
  grid-auto-flow: row;
}

.perks_grid h3 {
  display: flex;
  align-items: center;
  gap: .5rem;
}

.perks_grid h3 span:first-child {
  background: var(--clr-blue);
  color: white;
  height: 55px;
  width: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}

.img01 {
  width: calc(100% + 12rem);
  margin: 0 -6rem;
}

.img02,
.img03,
.img04 {
  width: calc(100% + 12rem);
  margin: 0 -6rem;
}

@media only screen and (min-width: 768px) {
  .perks_grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid_polaroid {
    grid-column: span 2;
  }

  .img01 {
    float: right;
    width: 60%;
    margin: 1rem;
  }

  .img01 {
    float: right;
    width: 60%;
  }

  .img03 {
    width: 80%;
    margin: 0 auto;
    display: block;
  }

  .img02 {
    width: 90%;
    margin: 0 auto;
    display: block;
  }

  .img04 {
    width: calc(100% + 12rem);
    margin: 0 -6rem;
  }
}