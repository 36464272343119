@keyframes iconIn {
  0% {
    transform: scale(0.1) rotate(0deg);
    opacity: 0;
    filter: blur(1rem);
  }
  70% {
    opacity: 1;
    filter: blur(.5rem);
    transform: scale(1.1) rotate(359deg);
  }
  100% {
    transform: scale(1) rotate(359deg);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes textIn {
  0% {
    opacity: 0;
    filter: blur(1rem);
  }
  70% {
    opacity: 1;
    filter: blur(.5rem);
    transform: scale(1.1)
  }
  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes punchIn {
  0% {
    display: none;
    opacity: 0;
    filter: blur(.1rem);
    transform: scale(100) rotate(50deg);
  }
  85% {
    opacity: 0;
    transform: scale(100) rotate(50deg);
    filter: blur(.1rem);
  }
  97% {
    transform: scale(.8);
    filter: blur(.1rem);
    opacity: .9;
  }

  100% {
    transform: scale(1);
    opacity: 1;
    filter: blur(0);
  }
}

@keyframes lineAnim {
  0%{
    filter: blur(.8rem);
    margin-left: -1rem;
  }
  50%{
    filter: blur(.1rem);
    margin-left: 0;
  }
  100%{
    filter: blur(.8rem);
    margin-left: -1rem;
  }
}

@keyframes arrowAnim {
  0%{
    bottom: 5vh;
  }
  80% {
    bottom: 5vh;
  }
  90% {
    bottom: 6.5vh;
  }
  95%{
    bottom: 5vh;
  }
  98%{
    bottom: 5.5vh
  }
  100%{
    bottom: 5vh;
  }
}

@keyframes textGlow {
  0%{
    text-shadow: 0px 0px 0px white;
  }
  50% {
    text-shadow: 0px 0px 10px white;
  }
  100%{
    text-shadow: 0px 0px 0px white;
  }
}

.hero {
  flex-grow: 1;
  flex-shrink: 1;
  background: rgb(58,90,101);
  background: var(--clr-invite-gradient);
  display: flex;
  align-items: center;
  justify-content: center;;
  height: var(--app-height);
  overflow: hidden;
  scroll-snap-align: start;
  position: relative;
}

.logo {
  height: max(90px, 30vw);
  position: relative;
}

@media screen and (min-width: 600px) {
  .logo {
    height: max(65px, 10vw);
  }
}

.logo picture > img {
  height: 100%;
}

.logoIcon {
  animation: iconIn;
  animation-duration: 2.5s;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.logoText {
  animation: textIn;
  animation-duration: 2.5s;
  margin-left: max(1.5rem, 3vw);
}

.logoPunch {
  height: max(18px, 4.5vw);
  position: absolute;
  right: 0;
  top: 100%;
  margin-bottom: min(-.8rem, -2vw);
  animation: punchIn;
  animation-duration: 3s;
  animation-timing-function: ease-out
}

.linesTop {
  height: 100px;
  position: absolute;
  top: -.3rem;
  right: -.5rem;
}

.line {
  opacity: .3;
  filter: blur(.8rem);
  margin-left: -.5rem;
}

.linesTop img {
  height: 100%;
}

.line1 {
  animation: lineAnim;
  animation-duration: 8s;
  animation-iteration-count:infinite;
  animation-delay: 4s;
}

.line2 {
  position: absolute;
  top:0;
  right: -.3rem;
  animation: lineAnim;
  animation-duration: 8s;
  animation-iteration-count:infinite;
}

.linesBottom {
  height: min(300px, 30vw);
  position: absolute;
  bottom: -1rem;
  left: -.5rem;
}

.linesEnter{
  opacity: 0;
}

.linesEnterActive{
  opacity: 1;
  transition: 3s;
}

.linesBottom img {
  height: 100%;
}

.line3 {
  animation: lineAnim;
  animation-duration: 8s;
  animation-iteration-count:infinite;
  animation-delay: 8s;
  margin-left: -1rem;
}

.line4 {
  position: absolute;
  left: -8rem;
  animation: lineAnim;
  animation-duration: 8s;
  animation-iteration-count:infinite;
  animation-delay: 6s;
  margin-left: -1rem;
}

.line5 {
  position: absolute;
  left: -8rem;
  bottom: -4rem;
  animation: lineAnim;
  animation-duration: 8s;
  animation-iteration-count:infinite;
}

.line6 {
  position: absolute;
  left: -.5rem;
  bottom: -6rem;
  width: 300px;
  animation: lineAnim;
  animation-duration: 8s;
  animation-iteration-count:infinite;
  animation-delay: 8s;

  margin-left: -1rem;
}

.arrow {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 3rem;
  color: white;
  background: transparent;
  border: 0;
  animation: arrowAnim 4s, textGlow 1s;
  animation-iteration-count:infinite;
  cursor: pointer;
}

.arrowEnter{
  opacity: 0;
}

.arrowEnterActive{
  opacity: 1;
  transition: 1s;
}